<template>
  <v-container
    id="login"
    fill-height
    class="flex-column justify-center align-center pa-0"
  >
    <v-scroll-y-reverse-transition>
      <div 
        v-if="view.scene.logo.show"
        class="logo-container d-flex align-center justify-start"
      >
        <icon-base
          v-if="breakpoint('xs')"
          width="20"
          height="24"
          icon-name="hive"
          class="hive mr-3 white--text"
        >
          <brand-symbol />
        </icon-base>
        <icon-base
          width="126"
          height="26"
          icon-name="Mobees"
          class="logo white--text"
        >
          <brand-logo />
        </icon-base>
      </div>
    </v-scroll-y-reverse-transition>
    <v-scroll-y-reverse-transition>
      <v-sheet
        v-if="view.scene.container.show"
        :width="breakpoint('xs') ? '420px' : '100%'"
        :height="breakpoint('xs') ? 'auto' : '100%'"
        elevation="1"
        color="transparent"
        dark
        class="login-container pa-12 py-8 d-flex flex-column justify-center rounded-lg"
      >
        <!-- <v-fade-transition> -->
        <h1 
          class="text-h3 accent--text font-weight-black text-uppercase my-6"
        >
          <v-img 
            src="/img/icons/dsp-app-icon.svg" 
            max-width="52px"
            class="app-logo d-inline-block mb-n2 mr-1"
          />
          {{ $t('general.go_app') }}
        </h1>
        <h2
          class="text-h4 text--off font-weight-light mb-8"
          v-html="$t('login.slogan', { br: '<br/>' })"
        />
        <!-- </v-fade-transition> -->
        <v-fade-transition>
          <v-form
            v-model="valid"
            :lazy-validation="lazy"
            class="login-form"
            ref="form"
          >
            <v-text-field
              v-model="username.input"
              :rules="username.rules"
              :loading="username.loading"
              :disabled="username.disabled||newPassword.token!=null"
              hide-details="auto"
              :label="$tc(password.forgot ? 'general.email' : 'general.user')"
              :messages="$t(password.forgot ? 'login.type_email' : '')"
              required
              class="control mb-4"
              name="username"
              ref="username"
              :autofocus="true"
              @keyup.enter="handleForm"
              outlined
            />

            <v-expand-transition>
              <v-text-field
                v-show="newPassword.token!=null"
                v-model="newPassword.new"
                :append-icon="newPassword.visible ? icons.mdiEye : icons.mdiEyeOff"
                :type="newPassword.visible ? 'text' : 'password'"
                :disabled="newPassword.disabled"
                @click:append="newPassword.visible = !newPassword.visible"
                :label="$t('login.create_password')"
                hide-details="auto"
                required
                class="control mt-6 mb-4"
                name="newPassword"
                ref="newPassword"
                outlined
              />
            </v-expand-transition>
            <v-expand-transition>
              <v-text-field
                v-show="newPassword.token!=null"
                v-model="newPassword.confirm"
                :append-icon="newPassword.visible ? icons.mdiEye : icons.mdiEyeOff"
                :type="newPassword.visible ? 'text' : 'password'"
                :disabled="newPassword.disabled"
                @click:append="newPassword.visible = !newPassword.visible"
                :label="$t('login.confirm_password')"
                hide-details="auto"
                required
                class="control mb-4"
                name="confirmPassword"
                ref="confirmPassword"
                outlined
              />
            </v-expand-transition>
            <v-expand-transition>
              <v-checkbox
                v-show="newPassword.token!=null&&newPassword.terms!=null"
                v-model="newPassword.terms"
                color="primary"
              >
                <a
                  slot="label"
                  href="#"
                  class="text-no-wrap text-body-2 white--text"
                  @click.stop="view.terms = true"
                >
                  {{ $t('login.read_accept_terms') }}
                </a>
              </v-checkbox>
            </v-expand-transition>


            <v-expand-transition>
              <v-text-field
                v-show="newPassword.token==null&&!password.forgot"
                v-model="password.input"
                :type="password.visible ? 'text' : 'password'"
                :disabled="password.disabled"
                hide-details
                :label="$t('login.password')"
                required
                class="control password-control mb-4"
                name="password"
                ref="password"
                outlined
                @keyup.enter="handleForm"
              >
                <template v-slot:append>
                  <v-expand-transition>
                    <a
                      v-show="newPassword.token==null&&!password.forgot"
                      class="text-overline accent--text"
                      @click="toggleForgot"
                    >
                      {{ $t('login.forgot') }}
                    </a>
                  </v-expand-transition>
                  <v-btn
                    icon
                    class="mt-n1 ml-2"
                    @click="password.visible = !password.visible"
                  >
                    <v-icon>{{ password.visible ? icons.mdiEye : icons.mdiEyeOff }}</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-expand-transition>

            <v-expand-transition>
              <v-text-field
                v-show="otp.toggle"
                v-model="otp.input"
                type="text"
                :disabled="otp.disabled"
                :label="$t('login.OTP')"
                hide-details="auto"
                required
                class="control mt-6 mb-4"
                name="otp"
                ref="otp"
                outlined
                @keyup.enter="handleForm"
              />
            </v-expand-transition>

            <v-card-actions class="pa-0">
              <v-spacer />
              <v-btn
                :elevation="0"
                :loading="submit.loading"
                :disabled="!enableSubmit"
                large
                color="accent"
                @click="handleForm"
                class="control px-4"
              >
                {{ $t(submitText) }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-fade-transition>
      </v-sheet>
    </v-scroll-y-reverse-transition>

    <terms-of-service 
      :toggle="view.terms"
      :fullscreen="!breakpoint('xs')"
      @toggle="toggleTerms" 
    />
  </v-container>
</template>

<style>

  #login .logo-container {
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: 1;
  }
  @media (max-width: 600px) {
    #login .logo-container {
      top: 40px;
      margin-left: 24px;
    }
  }

  #login .hive {
    width: 26px;
  }

  #login .logo {
    height: 20px;
    margin-bottom: 6px;
  }

  #login .login-form {
    width: 100%;
    max-width: 360px;
  }
  .control {
    margin: 16px 0;
  }

  #login .login-container {
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);
    background-color: rgba(255, 255, 255, 0.96);
  }
  #login .heading {
    font-size: 2.25rem !important;
    line-height: 1.4;
  }

  #login .help-btn {
    text-transform: initial;
    width: 72px;
    height: 72px;
    margin: -24px -24px -24px 0 !important;
  }

  #login .password-control .v-input__append-inner {
    margin-top: 12px !important;
  }

  .text--off {
    opacity: .8;
  }
</style>

<script>

  // Icons
  import {
    mdiHelpCircleOutline,
    mdiEye,
    mdiEyeOff,
    mdiClose,
    mdiArrowRight
  } from '@mdi/js'

  // Utilities
  import services from '@/services.js'
  import { auth as getAuth, createPassword, resetPassword } from '@/api/users.js'
  import { sync } from 'vuex-pathify'

  export default {
    name: 'Login',
    metaInfo: {
      title: 'Login'
    },

    components: {
      IconBase: () => import('@/components/IconBase'),
      BrandSymbol: () => import('@/components/icons/BrandSymbol'),
      TermsOfService: () => import('@/components/TermsOfService'),
    },

    data: () => ({
      icons: {
        mdiHelpCircleOutline,
        mdiEye,
        mdiEyeOff,
        mdiClose,
        next: mdiArrowRight
      },
      view: {
        scene: {
          logo: {
            show: false,
            time: 500,
          },
          container: {
            show: false,
            time: 250,
          },
          heading: {
            show: false,
            time: 250,
          },
          form: {
            show: false,
            time: 250,
          },
        },
        terms: false
      },
      valid: false,
      lazy: true,
      username: {
        input: '',
        loading: false,
        disabled: false,
      },
      password: {
        input: null,
        forgot: false,
        disabled: false,
        loading: false,
        visible: false,
      },
      newPassword: {
        new: null,
        confirm: null,
        rules: [
          v => (!!v && v) === this.newPassword.new || 'A confirmação deve ser igual à senha.'
        ],
        disabled: false,
        loading: false,
        visible: false,
        token: null,
        terms: null
      },
      otp: {
        input: null,
        toggle: false,
        loading: false,
        disabled: false,
      },
      submit: {
        loading: false,
        disabled: false
      },
      redirect: null,
      baseUrl: process.env.VUE_APP_ROOT
    }),

    computed: {
      user: sync('user/data'),
      brands: sync('clients/items'),
      // help: sync('help'),
      // redirect: sync('app/redirect'),
      toast: sync('app/toast'),

      enableSubmit () {
        var enable = false;
        const username = this.username.input;
        const password = this.password.input;
        const forgot = this.password.forgot;
        const newPassword = this.newPassword.new;
        const confirmPassword = this.newPassword.confirm;
        const terms = this.newPassword.terms;

        if (this.newPassword.token==null) {
          var a = username != null && username != '';
          var b = password != null && password != '';
          enable = a&&(b||forgot);
        }else{
          var a = username != null && username != '';
          var b = newPassword != null && newPassword != '' && newPassword===confirmPassword;
          var c = terms!=false
          enable = a&&b&&c;
        }
        return enable;
      },

      submitText () {
        return this.password.forgot ? 'login.recover_password' : this.newPassword.terms==null && this.newPassword.token!=null ? 'login.create_password' : 'login.access';
      },
    },

    watch: {
      $route: {
        immediate: true,
        deep: true,
        handler (route) {
          if (_.has(route.query, 'redirect')) {
            let url = decodeURIComponent(route.query.redirect);
            if (_.startsWith(url, this.baseUrl)) url = _.replace(url, this.baseUrl, '')
            this.redirect = url;
          }
          if (_.has(route.params, 'token')) {
            this.username.input = route.params.username;
            this.newPassword.token = route.params.token;
          }
          if (route.name=='Access') this.newPassword.terms = false;
        },
      } 
    },

    methods: {
      ...services,
      getAuth,
      createPassword,
      resetPassword,
      
      handleForm () {
        if (this.newPassword.token==null) {
          // Login password
          if(this.password.input.length){
            this.submit.disabled = this.submit.loading = true;
            this.login(this.username.input, this.password.input, this.otp.input);
          }else{
            if (this.password.forgot) {
              this.forgotPassword(this.username.input);
            }else{
              console.log('focus',this.$refs.password.$el);
              this.$refs.password.$el.focus();
            }
          }
        }else {
          // Create password
          this.submit.disabled = this.submit.loading = true;
          this.setPassword(this.username.input, this.newPassword.confirm, this.newPassword.token);
        }
      },

      toggleForgot () {
        this.password.forgot = !this.password.forgot;
        if (this.password.forgot && this.enableSubmit) this.forgotPassword(this.username.input);
      },

      toggleTerms (accept) {
        this.view.terms = !this.view.terms;
        if (accept===true) this.newPassword.terms = accept;
      },

      forgotPassword (username) {
        this.submit.loading = true;
        this.resetPassword(
          username,
          () => {
            this.toggleToast(
              true,
              'Enviamos um link de acesso para o email. Verifique sua caixa de entrada.',
              10000,
              false,
            );
          },
          error => {
            if (_.has(error.response, 'status') && error.response.status==404) {
              this.toggleToast(
                true,
                'O email informado não está cadastrado.',
                10000,
                false,
              );
              this.submit.loading = false;
            }else{
              this.handleError(error, 'Ocorreu um erro inesperado. Tente novamente, por favor.', true);
            }
          },
          () => {
            this.submit.loading = false;
          }
        )
      },
      setPassword (username, password, token) {
        this.submit.loading = true;
        this.createPassword(
          username,
          password,
          token,
          (response) => {
            this.login(username, password);
          },
          (error) => {
            if (_.has(error.response, 'status') && error.response.status==403) {
              this.toggleToast(
                true,
                'Link de acesso inválido. Enviamos um novo link para o email cadastrado.',
                5000,
                false,
              );
            }else{
              this.toggleToast(
                true,
                'Ocorreu um erro inesperado. Tente novamente, por favor',
                5000,
                false,
              );
              this.handleError(error, 'Ocorreu um erro inesperado. Tente novamente, por favor.');
            }
            this.submit.loading = false;
          },
          () => {
            this.submit.loading = false;
          }
        )
      },
      login (username, password, otp) {
        this.submit.loading = true;
        this.getAuth(
          username, 
          password, 
          otp,
          (data, status) => {
            if (status==206) {
              this.otp.toggle = true;
              this.toggleToast(
                true,
                otp==null ? 'Preencha o código de autenticação em 2 fatores' : 'Usuário, Senha e/ou OTP inválidos. Verifique seus dados.',
                5000,
                false,
              );
            }else{
              this.brands = Object.assign({}, _.mapValues(_.keyBy(data.marcas, 'id_marca'), (b) => {
                return {
                  id: b.id_marca,
                  category: b.cat_marca,
                  title: b.nm_marca,
                  discount: b.desconto,
                  logotype: null,
                  agencies: [],
                  users: []
                }
              }))
              if (_.size(this.brands)>0) {
                let user = {
                  username: username,
                  auth: {
                    token: data.jwtToken,
                    timestamp: this.$moment().valueOf(),
                  },
                  roles: _.map(data.perfis, role => role.id_perfil),
                  advertisers: _.keyBy(_.map(data.anunciantes, advertiser => {
                    return { 
                      id: advertiser.id_anunciante,
                      brand_id: advertiser.id_marca,
                      agency_id: advertiser.id_agencia,
                    }
                  }), 'id'),
                  brands: _.keys(this.brands)
                }
                user.agency = _.intersection(user.roles, [6,17])>0 ? data.anunciantes[0].id_agencia : null;
                this.user = Object.assign({}, this.user, user);
    
                localStorage.setItem('user', JSON.stringify(this.user));

                let brand = localStorage.getItem("brand");
                brand = _.indexOf(this.user.brands, brand)>=0 ? brand : null;

                setTimeout(($) => {
                  if (_.isNil($.redirect)) {
                    $.$router.push({ name: 'Overview', params: _.isNil(brand) ? {} : { brand } });
                  }else{
                    $.$router.push($.redirect)
                  }
                }, 250, this);
              }else{
                this.toggleToast(
                  true,
                  'Acesso negado.',
                  5000,
                  false,
                );
              }
            }
          },
          error => {
            if (_.has(error.response, 'status') && error.response.status==401) {
              this.toggleToast(
                true,
                'Usuário e/ou Senha inválidos. Verifique seus dados.',
                5000,
                false,
              );
              this.submit.loading = false;
            }else{
              this.handleError(error, 'Ocorreu um erro inesperado. Tente novamente, por favor.', true);
            }
          },
          () => {
            this.submit.loading = false;
          })
      },

      startScene () {
        const next = _.find(this.view.scene, ['show', false]);
        if (!_.isNil(next)) {
          setTimeout(($, next) => {
            console.log(next);
            next.show = true;
            $.startScene();
          }, next.time, this, next);
        }
      }
    },

    mounted () {
      this.cancelToasts();
      this.startScene();
    },
  }
</script>
